<template>
  <!--车辆编辑-->
  <div>
    <div class="title">
      <div class="title_t">
        <span>车辆信息</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="150px">

      <el-card shadow="never">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #202033;">车辆信息</span>
        </div>

        <el-row :gutter="15">
          <el-col :span="5">
            <div style="margin-top: 10px;">
              <div class="upload_bg_shouchi">
                <el-upload
                    :limit="1"
                    :class="{hide_box: handupload_btn}"
                    :on-change="handchange"
                    :action="action"
                    :headers="headers"
                    :on-success="successUpload3"
                    :file-list="fileList3"
                    list-type="picture-card"
                    :on-preview="handPreview"
                    :on-remove="handhandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 20px;">行驶证上传</div>
                </el-upload>
                <el-dialog :visible.sync="handdialogVisible">
                  <img width="100%" :src="handdialogImageUrl" alt="">
                </el-dialog>
              </div>
            </div>
            <div style="margin-top: 10px;">
              <div class="upload_bg_shouchi">
                <el-upload
                    :limit="1"
                    :class="{hide_box: bodyupload_btn}"
                    :on-change="bodychange"
                    :action="action"
                    :headers="headers"
                    :on-success="successUpload4"
                    :file-list="fileList4"
                    list-type="picture-card"
                    :on-preview="bodyPreview"
                    :on-remove="bodyhandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 20px;">车身45°</div>
                </el-upload>
                <el-dialog :visible.sync="bodydialogVisible">
                  <img width="100%" :src="bodydialogImageUrl" alt="">
                </el-dialog>
              </div>
            </div>
          </el-col>
          <el-col :span="19">
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="车牌号" prop="license_plate_number">
                  <el-input clearable style="" v-model="form.license_plate_number" placeholder="请输入车牌号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车辆归属" prop="affiliation">
                  <el-select
                    clearable
                    v-model="form.affiliation"
                    placeholder="请选择"
                    class="ele-fluid">
                    <el-option
                      v-for="(item,index) in service"
                      :label="item.name"
                      :value="item.value"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="所有人" prop="holder">
                  <el-input clearable style="" v-model="form.holder" placeholder="请输入所有人"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="状态" prop="hold_condition">
                  <el-select
                      clearable
                      v-model="form.hold_condition"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option
                      v-if="item.label != '全部'"
                      v-for="(item) in dict.type.vehiclestate"
                      :label="item.label"
                      :value="parseInt(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            
            <el-row :gutter="15">
              <el-col :span="12">
                  <el-form-item label="车辆是否认证" prop="">
                    <el-radio v-model="form.radio" label="1">是</el-radio>
                    <el-radio v-model="form.radio" label="2">否</el-radio>
                  </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="汽车类型" prop="vehicle_mold">
                  <el-select
                    clearable
                    v-model="form.vehicle_mold"
                    placeholder="请选择"
                    class="ele-fluid">
                    <el-option
                      v-if="item.label != '全部'"
                      v-for="(item) in dict.type.vehicletype"
                      :label="item.label"
                      :value="parseInt(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="品牌型号" prop="vehicle_brand">
                  <el-input clearable style="" v-model="form.vehicle_brand" placeholder="请输入品牌型号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="车架号码" prop="vin">
                  <el-input clearable style="" v-model="form.vin" placeholder="请输入车架号码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发动机号" prop="engine_number">
                  <el-input clearable style="" v-model="form.engine_number" placeholder="请输入发动机号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="黄蓝牌" prop="plate_type">
                  <el-select
                      clearable
                      v-model="form.plate_type"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option
                      v-for="(item) in dict.type.Licenseplatetype"
                      :label="item.label"
                      :value="parseInt(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册日期" prop="registration_date">
                  <el-date-picker
                      v-model="form.registration_date"
                      value-format="yyyy-MM-dd"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="GPS类型" prop="gps_type">
                  <el-select
                      clearable
                      v-model="form.gps_type"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option
                      v-for="(item) in dict.type.gpsType"
                      :label="item.label"
                      :value="parseInt(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="终端编号" prop="terminal_number">
                  <el-input clearable style="" v-model="form.terminal_number" placeholder="请输入终端编号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-card>

      <el-card shadow="never">
        <el-row :gutter="20">
          <el-col :span="8">
            <div style="display: flex;align-items: center;margin-bottom: 10px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 500;color: #3f4155;">交强险</span>
            </div>
            <div class="upload_bg_jiaoqiangxian">
              <el-upload
                  :limit="1"
                  :class="{hide_box: compulsoryTrafficInsuranceupload_btn}"
                  :on-change="compulsoryTrafficInsurancechange"
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload"
                  :file-list="fileList"
                  list-type="picture-card"
                  :on-preview="compulsoryTrafficInsurancePreview"
                  :on-remove="compulsoryTrafficInsurancehandleRemove">
                <!--<i class="el-icon-plus"></i>-->
                <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
              </el-upload>
              <el-dialog :visible.sync="compulsoryTrafficInsurancedialogVisible">
                <img width="100%" :src="compulsoryTrafficInsurancedialogImageUrl" alt="">
              </el-dialog>
            </div>
            <div style="margin-top: 20px;">
              <el-form-item label="投保公司" label-width="80px">
                <el-input clearable style="" v-model="form.insurance.mlifta.corporation" placeholder="请输入投保公司"></el-input>
              </el-form-item>
              <el-form-item label="到期时间" label-width="80px">
                <el-date-picker
                    v-model="form.insurance.mlifta.expiration_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="保单号" label-width="80px">
                <el-input clearable style="" v-model="form.insurance.mlifta.warranty_number" placeholder="请输入保单号"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="display: flex;align-items: center;margin-bottom: 10px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 500;color: #3f4155;">商业险</span>
            </div>
            <div class="upload_bg_jiaoqiangxian">
              <el-upload
                  :limit="1"
                  :class="{hide_box: businessupload_btn}"
                  :on-change="businesschange"
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload1"
                  :file-list="fileList1"
                  list-type="picture-card"
                  :on-preview="businessPreview"
                  :on-remove="businessRemove">
                <!--<i class="el-icon-plus"></i>-->
                <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
              </el-upload>
              <el-dialog :visible.sync="businessdialogVisible">
                <img width="100%" :src="businessdialogImageUrl" alt="">
              </el-dialog>
            </div>
            <div style="margin-top: 20px;">
              <el-form-item label="投保公司" label-width="80px">
                <el-input clearable style="" v-model="form.insurance.rot.corporation" placeholder="请输入投保公司"></el-input>
              </el-form-item>
              <el-form-item label="到期时间" label-width="80px">
                <el-date-picker
                  v-model="form.insurance.rot.expiration_time"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="保单号" label-width="80px">
                <el-input clearable style="" v-model="form.insurance.rot.warranty_number" placeholder="请输入保单号"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="display: flex;align-items: center;margin-bottom: 10px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 500;color: #3f4155;">拖挂险</span>
            </div>
            <div class="upload_bg_jiaoqiangxian">
              <el-upload
                  :limit="1"
                  :class="{hide_box: draggingupload_btn}"
                  :on-change="draggingchange"
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload2"
                  :file-list="fileList2"
                  list-type="picture-card"
                  :on-preview="draggingPreview"
                  :on-remove="draggingRemove">
                <!--<i class="el-icon-plus"></i>-->
                <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
              </el-upload>
              <el-dialog :visible.sync="draggingdialogVisible">
                <img width="100%" :src="draggingdialogImageUrl" alt="">
              </el-dialog>
            </div>
            <div style="margin-top: 20px;">
              <el-form-item label="投保公司" label-width="80px">
                <el-input clearable style="" v-model="form.insurance.ci.corporation" placeholder="请输入投保公司"></el-input>
              </el-form-item>
              <el-form-item label="到期时间" label-width="80px">
                <el-date-picker
                  v-model="form.insurance.ci.expiration_time"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="保单号" label-width="80px">
                <el-input clearable style="" v-model="form.insurance.ci.warranty_number" placeholder="请输入保单号"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-card>

      <div style="margin-top: 30px;text-align: center;">
        <el-button style="width: 200px !important;" @click="returnPage">取消</el-button>
        <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save('form')">提交</el-button>
      </div>


    </el-form>
  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import {Addvehicles, Detailsvehicles, Editvehicles} from "@/api/vehicle";
import {commonfacilitator} from '@/api/yunli'

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // id
    id:Number
  },
  // 组件生效
  components: {

  },
  // Data数据
  data() {
    return {
      isUpdate:false,
      form: {},
      // 验证规则
      rules: {
        license_plate_number: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
          { min: 0, max: 8, message: '长度最多输入8个字符', trigger: 'blur' }
        ],
        holder: [
          { required: true, message: '请输入所有人', trigger: 'blur' },
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        hold_condition: [
          { required: true, message: '请选择状态', trigger: 'change' },
        ],
        vehicle_mold:[
          { required: true, message: '请选择汽车类型', trigger: 'change' },
        ],
        vehicle_brand:[
          { required: true, message: '请输入品牌型号', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        vin:[
          { required: true, message: '请输入车架号', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        engine_number:[
          { required: true, message: '请输入发动机号', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        registration_date:[
          { required: true, message: '请选择注册日期', trigger: 'change' }
        ],
        gps_type:[
          { required: true, message: '请选择GPS类型', trigger: 'change' }
        ],
        plate_type:[
          { required: true, message: '请选择黄蓝牌', trigger: 'change' }
        ],
        terminal_number:[
          { required: true, message: '请输入终端编号', trigger: 'blur' },
          { min: 0, max: 20, message: '长度最多输入20个字符', trigger: 'blur' }
        ],
        affiliation: [
          { required: true, message: '请选择车辆归属', trigger: 'change' }
        ]
      },

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/vehicle',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //行驶证照片
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,
      fileList3:[],

      //车身
      bodydialogImageUrl:'',
      bodydialogVisible:false,
      bodyupload_btn:false,
      fileList4:[],

      //交强险
      compulsoryTrafficInsurancedialogImageUrl:'',
      compulsoryTrafficInsurancedialogVisible:false,
      compulsoryTrafficInsuranceupload_btn:false,
      fileList:[],

      //商业险
      businessupload_btn:false,
      businessdialogImageUrl:'',
      businessdialogVisible:false,
      fileList1:[],

      //拖挂险
      draggingupload_btn:false,
      draggingdialogImageUrl:'',
      draggingdialogVisible:false,
      fileList2:[],

      // 服务商
      service:[],

    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    if (this.data) {
      // this.form = Object.assign({}, this.data);
      // 调用详情接口
      Detailsvehicles(this.id).then(res => {
        this.form = res.data;
        console.log(this.form);
        //行驶证照片
        if(this.form.driving_license != ''){
          let data = {
            url: this.form.driving_license
          };
          this.handupload_btn = true;
          this.fileList3.push(data);
        }
        //车身
        if(this.form.bodywork != ''){
          let data = {
            url: this.form.bodywork
          };
          this.bodyupload_btn = true;
          this.fileList4.push(data);
        }

        //交强险
        if(this.form.insurance.mlifta != null){
          if(this.form.insurance.mlifta.img_dir != ''){
            let data = {
              url: this.form.insurance.mlifta.img_dir
            };
            this.compulsoryTrafficInsuranceupload_btn = true;
            this.fileList.push(data);
          }
        }

        //商业险
        if(this.form.insurance.ci != null){
          if(this.form.insurance.ci.img_dir != ''){
            let data = {
              url: this.form.insurance.ci.img_dir
            };
            this.businessupload_btn = true;
            this.fileList1.push(data);
          }
        }

        //拖挂险
        if(this.form.insurance.rot != null){
          if(this.form.insurance.rot.img_dir != ''){
            let data = {
              url: this.form.insurance.rot.img_dir
            };
            this.draggingupload_btn = true;
            this.fileList2.push(data);
          }
        }
      })
      this.isUpdate = true;
    } else {
      this.form = {
        insurance:{
          mlifta:{},
          ci:{},
          rot:{},
        }
      };
      this.isUpdate = false;
    }

    // 获取所有服务商
    this.getcommonfacilitator();
  },
  // 方法集合
  methods: {
    // 返回上级页面
    returnPage(){
      this.$emit('showEdit',false) ;
    },

    // 获取服务商列表包含自己
    getcommonfacilitator(){
      commonfacilitator().then(res => {
        this.service = res.data;
      })
    },

    //行驶证照片
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    successUpload3(file,fileList){
      // 上传成功后的列表
      this.form.driving_license = file.data.urls;
    },


    //车身45
    bodychange(){
      this.bodyupload_btn = true;
    },
    bodyhandleRemove(){
      this.bodyupload_btn = false;
    },
    bodyPreview(file){
      this.bodydialogImageUrl = file.url;
      this.bodydialogVisible = true;
    },
    successUpload4(file,fileList){
      // 上传成功后的列表
      this.form.bodywork = file.data.urls;
    },

    //交强险
    compulsoryTrafficInsurancechange(){
      this.compulsoryTrafficInsuranceupload_btn = true;
    },
    compulsoryTrafficInsurancehandleRemove(){
      this.compulsoryTrafficInsuranceupload_btn = false;
    },
    compulsoryTrafficInsurancePreview(file){
      this.compulsoryTrafficInsurancedialogImageUrl = file.url;
      this.compulsoryTrafficInsurancedialogVisible = true;
    },
    successUpload(file,fileList){
      // 上传成功后的列表
      this.form.insurance.mlifta.img_dir = file.data.urls;
    },

    //商业险
    businesschange(){
      this.businessupload_btn = true;
    },
    businessRemove(){
      this.businessupload_btn = false;
    },
    businessPreview(file){
      this.businessdialogImageUrl = file.url;
      this.businessdialogVisible = true;
    },
    successUpload1(file,fileList){
      // 上传成功后的列表
      this.form.insurance.ci.img_dir = file.data.urls;
    },

    //拖挂险
    draggingchange(){
      this.draggingupload_btn = true;
    },
    draggingRemove(){
      this.draggingupload_btn = false;
    },
    draggingPreview(file){
      this.draggingdialogImageUrl = file.url;
      this.draggingdialogVisible = true;
    },
    successUpload2(file,fileList){
      // 上传成功后的列表
      this.form.insurance.rot.img_dir = file.data.urls;
    },

    // 提交
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断是创建还是编辑
          if(this.isUpdate === false){
            //创建
            Addvehicles(this.form).then(res => {
              console.log(res)
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit('showEdit',false) ;
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }else {
            //编辑
            Editvehicles(this.id,this.form).then(res => {
              console.log(res)
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit('showEdit',false) ;
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },


  },
}
</script>

<style scoped lang="scss">
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
