import request from '@/utils/request'

/**
 * 车辆管理 列表
 * @returns {*}
 */
export function getVehicle (params) {
    return request({
        url: 'vehicle/vehicles',
        method: 'get',
        params
    })
}

/**
 * 车辆管理 详情
 * @returns {*}
 */
export function Detailsvehicles (id,params) {
  return request({
    url: `vehicle/vehicles/${id}`,
    method: 'get',
    params
  })
}

/**
 * 车辆管理 新增
 * @returns {*}
 */
export function Addvehicles (data) {
  return request({
    url: `vehicle/vehicles`,
    method: 'post',
    data
  })
}

/**
 *  车辆管理 修改
 * @returns {*}
 */
export function Editvehicles (id,data) {
  return request({
    url: `vehicle/vehicles/${id}`,
    method: 'put',
    data
  })
}

/**
 * 车辆管理 删除
 * @returns {*}
 */
export function Delvehicles (id) {
  return request({
    url: `vehicle/vehicles/${id}`,
    method: 'delete',
  })
}

/**
 * 车辆统计
 * @returns {*}
 */
export function getstatistics (params) {
  return request({
    url: 'vehicle/vehicle/statistics?',
    method: 'get',
    params
  })
}
